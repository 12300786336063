.app__mutation {
    // flex: 1;
    width: 100%;
    // margin: 0 2rem 0 2rem;
    flex-direction: column;
    // margin: 10rem 10rem;
    
}

b {
    color: var(--secondary-color);
}

.app__mutation-portfolio {
    padding-top: 3rem;
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto 2rem;
    @media screen and (min-width: 2000px) {
        margin: auto 3rem;
      }
    

    .app__mutation-item {
        width: 100%;
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.07);
        margin: 1rem;
        padding: 1rem 5rem ;
        border-radius: 1rem;
        background-color: #fff;
        color: #000;
    }
}

.app__mutation-image {
    width: 100%;
    // height: 100%;
    // padding: 4rem;
    // position: relative;

    img {
        max-width: 100%;
        // max-height: 100%;
        border-radius: 1rem;
        object-fit: cover;
    }
}

.app__mutation-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
  }

.app__mutation-gif{
    width: 20px;
}

    // .background__content {
    //     text-align: center;
    //     justify-content: center;
    // }



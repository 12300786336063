

body{
    // background-color: rgb(48, 48, 48);
    .app__typing{
        display: flex;
        background-color: rgb(48, 48, 48);
        flex: 1;
        width: 100%;
        flex-direction: column;
    }
    
    .big-text{
        font-size: 6rem;
        font-weight: 500;
        color: white;
        span {
            color: white;
        }

        @media screen and (max-width: 1000px) {
            font-size: 4rem;
            font-weight: 450;
        }
    }

    .app__typing-text{
        margin-top: 0.5rem;
    }
    .app__typing-other{
        margin-left: 0;
        margin-top: 15rem;
    }
}




// .app__typing-text{
//     font-size: 2.75rem;
//     font-weight: 800;
//     text-align: center;
//     color: var(--black-color);
//     // text-transform: capitalize;
  
//     span {
//       color: white;
//     }
// }
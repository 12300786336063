.app__works {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.head-text {
  margin-top: 100px;
}
.app__work-filter{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  margin: 2rem 0 1rem;

  .app__work-filter-item {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      background-color: white;
      color: black;

      font-weight: var(--fw-bold);
      cursor: pointer;
      transition: all 0.3s ease;
      margin: 0.5rem;

      &:hover {
          background-color: var(--secondary-color);
          color: white;
      }

      @media screen and (min-width: 2000px) {
          padding: 1rem 2rem;
          border-radius: 0.85rem;
      }
  }

  .item-active {
      background-color: var(--secondary-color);
      color: white;
  }
}

.app__work-portfolio {
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;

  .app__work-item {
      width: 100%;
      flex-direction: row;
      gap: 10rem;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
      margin: 1rem;
      padding: 1rem;
      border-radius: 1rem;
      background-color: #fff;
      color: #000;
  
      cursor: pointer;
      transition: all 0.3s ease;
  
      // &:hover {
      // box-shadow: 0 0 25px rgba(0, 0, 0, 0.10);
      // }

      @media screen and (min-width: 2000px) {
      width: 70%;
      padding: 1.25rem;
      border-radius: 0.75rem;
      }
    
      @media screen and (max-width: 1000px) {
      width: 100%;
      margin: 1rem;
      flex-direction: column;
      gap: 1rem;
      }
  }
}

.app__work-img {
  width: 100%;
  height: 230px;

  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}

.app__work-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.3s ease;

  div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;

      margin: 1rem;
      font-family: var(--font-base);
      font-weight: 800;
      cursor: pointer;
      transition: all 0.3s ease;
      
      svg {
      width: 50%;
      height: 50%;
      color: var(--white-color);
      }
  }
}

.app__work-content {
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;

  h2 {
    margin-top: 1rem;
    @media screen and (min-width: 2000px) {
    }
  }
  .p-text {
      text-align: left;

      @media screen and (max-width: 1000px) {
          text-align: center;
      }
  }
}
